import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetApiClient } from "../../../api/useApiClient";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import image1 from "../../../assets/images/page-img/img-success.png";

const CreateARC = () => {
  const data = [
    {
      id: 0,
      Name: "------",
      Reconsiderations: [],
      Forms: [],
      Description: "------",
      DateCreated: "------",
      DateClosed: "------",
      Status: "------",
    },
  ];

  const [Add, setAdd] = useState(data);
  const [show, setShow] = useState("A");
  const [validated, setValidated] = useState(false);

  const [arcId, setArcID] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [closingDate, setClosingDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [description, setDescription] = useState("");
  const [materials, setMaterials] = useState("");
  const [reconsiderations, setReconsiderations] = useState([]);
  const [documents, setDocuments] = useState([]);

  const [uploadedDocuments, setUploadedDocuments] = useState({});
  const [uploadDocPath, setUploadDocPath] = useState([]);
  const [uploadedDocuName, setUploadedDocuNames] = useState([]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    console.log("arcid: " + arcId);
    console.log("description: " + description);
    console.log("materials: " + materials);
    console.log(reconsiderations);
    console.log(documents);
  };

  async function list() {
    const response = await useGetApiClient.get(`/homeowner/arc/list`);
    if (response.status == 200) {
      const jsondata = response.data;
      const requests = jsondata.arcTypes.map((request) => ({
        id: request.id,
        Name: request.name,
        Reconsiderations: request.reconsiderations,
        Forms: request.forms,
      }));

      setAdd(requests);
    } else {
      let jsondata = await response.json();
      console.log(jsondata);
    }
  }

  useEffect(() => {
    list();
  }, []);

  const handleReconsiderationChange = (index, value) => {
    setReconsiderations((prev) => {
      const newReconsiderations = [...prev];
      newReconsiderations[index] = {
        ...newReconsiderations[index],
        answer: value,
      };
      return newReconsiderations;
    });
  };

  async function save() {
    if (uploadedDocuments.length === 0) {
      alert("Please upload at least one document.");
      return;
    }

    const payload = {
      arcId: parseInt(arcId),
      startDate,
      endDate,
      closingDate,
      description,
      materials,
      reconsiderations: reconsiderations.map((item) => ({
        question: item.question,
        answer: item.answer,
      })),
      documents: uploadedDocuName.map((name, index) => ({
        name: name,
        file: uploadDocPath[index],
      })),
    };

    console.log("Payload being sent:", payload);

    try {
      const response = await useGetApiClient.post(
        "/homeowner/arc/save",
        payload
      );

      if (response.status === 201) {
        toast.log("ARC saved successfully:", response.data);
        alert("ARC saved successfully!");
      } else {
        console.error("Failed to save ARC:", response.data);
        alert(
          `Failed to save ARC: ${response.data.message || "Unknown error"}`
        );
      }
    } catch (error) {
      console.error("Error saving ARC:", error.response?.data || error.message);
      alert(
        `Error saving ARC: ${error.response?.data.message || error.message}`
      );
    }
  }

  const handleDownload = async (fileUrl) => {
    try {
      const response = await useGetApiClient.get(fileUrl, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileUrl.split("/").pop());
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleFileChange = (e, docIndex) => {
    const file = e.target.files[0];
    const fileName = file.name;
    if (file) {
      setUploadedDocuments((prev) => ({
        ...prev,
        [docIndex]: {
          file: file,
          name: fileName,
        },
      }));
    }
    uploadDocu(file, docIndex, fileName);
  };

  const uploadDocu = async (file, docIndex, fileName) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await useGetApiClient.post(
      `/homeowner/arc/upload/document`,
      formData
    );
    if (response.status === 201) {
      const json = response.data;
      console.log(json.directoryPath);
      setUploadedDocuNames((prev) => [...prev, fileName]);
      setUploadDocPath((prev) => [...prev, json.directoryPath]);
    } else {
      console.log(response);
    }
  };

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">ARC Request</h3>
                  <p className="text-white">Create ARC Request</p>
                </div>
              </Card>
            </Col>
            <Col sm="12" lg="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Request Form</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <Form
                    className="text-center"
                    noValidate
                    validated={validated}
                    // onSubmit={handleSubmit}
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                      if (validated) save();
                    }}
                  >
                    <ul id="top-tab-list" className="p-0 row list-inline">
                      <li
                        className={` ${
                          show === "Documents" ? "active done" : ""
                        } ${show === "Description" ? "active done" : ""} ${
                          show === "Dates" ? "active done" : ""
                        } ${
                          show === "A" ? "active " : ""
                        } col-lg-3 col-md-6 text-start mb-2 active`}
                        id="Dates"
                      >
                        <Link to="#">
                          <i className="material-symbols-outlined">
                            edit_calendar
                          </i>
                          <span>Dates</span>
                        </Link>
                      </li>
                      <li
                        id="Description"
                        className={` ${
                          show === "Documents" ? "active done" : ""
                        } ${show === "Description" ? "active done" : ""} ${
                          show === "Dates" ? "active" : ""
                        } col-lg-3 col-md-6 mb-2 text-start`}
                      >
                        <Link to="#">
                          <i className="material-symbols-outlined">person</i>
                          <span>Description</span>
                        </Link>
                      </li>
                      <li
                        id="payment"
                        className={` ${
                          show === "Documents" ? "active done" : ""
                        } ${
                          show === "Description" ? "active " : ""
                        } col-lg-3 col-md-6 mb-2 text-start`}
                      >
                        <Link to="#">
                          <i className="material-symbols-outlined">
                            description
                          </i>
                          <span>Documents</span>
                        </Link>
                      </li>
                      <li
                        id="confirm"
                        className={` ${
                          show === "Documents" ? "active done" : ""
                        } col-lg-3 col-md-6 mb-2 text-start`}
                      >
                        <Link to="#">
                          <i className="material-symbols-outlined">done</i>
                          <span>Finish</span>
                        </Link>
                      </li>
                    </ul>
                    <fieldset
                      className={`${show === "A" ? "d-block" : "d-none"}`}
                    >
                      <div className="form-card text-start">
                        <Row>
                          <div className="col-7">
                            <h3 className="mb-4">Request & Dates</h3>
                          </div>
                        </Row>
                        <Row>
                          <Col md="6">
                            <Form.Group controlId="arcType">
                              <Form.Label>ARC Type: *</Form.Label>
                              <Form.Select
                                required
                                value={arcId}
                                onChange={(e) => {
                                  setArcID(e.target.value);
                                  const selectedArc = Add.find(
                                    (item) =>
                                      item.id === parseInt(e.target.value)
                                  );
                                  setReconsiderations(
                                    selectedArc
                                      ? selectedArc.Reconsiderations.map(
                                          (rec) => ({
                                            question: rec,
                                            answer: false,
                                          })
                                        )
                                      : []
                                  );
                                  setDocuments(
                                    selectedArc ? selectedArc.Forms : []
                                  );
                                }}
                              >
                                <option value="" disabled>
                                  {" "}
                                  Select ARC Types
                                </option>
                                {Add.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.Name}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select an ARC Type.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label>Start Date: *</Form.Label>
                              <Form.Control
                                type="date"
                                value={startDate}
                                required
                                onChange={(e) => setStartDate(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label>End Date: *</Form.Label>
                              <Form.Control
                                type="date"
                                value={endDate}
                                required
                                onChange={(e) => setEndDate(e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide end date
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label>Closing Date: *</Form.Label>
                              <Form.Control
                                type="date"
                                value={closingDate}
                                required
                                onChange={(e) => setClosingDate(e.target.value)}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please provide a closing date.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <Button
                        variant="primary"
                        className="next action-button float-end"
                        onClick={(e) => {
                          handleSubmit(e);
                          if (validated) setShow("Dates");
                        }}
                      >
                        Next
                      </Button>
                    </fieldset>
                    <fieldset
                      className={`${show === "Dates" ? "d-block" : "d-none"}`}
                    >
                      <div className="form-card text-start">
                        <Row>
                          <div className="col-7">
                            <h3 className="mb-4">Description of Request:</h3>
                          </div>
                        </Row>
                        <Row>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label>Detailed Description</Form.Label>
                              <Form.Control
                                as="textarea"
                                required
                                value={description}
                                placeholder="ARC Detailed Description"
                                onChange={(e) => setDescription(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group className="form-group">
                              <Form.Label>Materials Needed</Form.Label>
                              <Form.Control
                                as="textarea"
                                required
                                value={materials}
                                placeholder="ARC Materials Needed"
                                onChange={(e) => setMaterials(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          {reconsiderations.map((reconsideration, index) => (
                            <Col md="6">
                              <Form.Group key={index} className="form-group">
                                <Form.Check
                                  type="checkbox"
                                  label={reconsideration.question}
                                  checked={reconsideration.answer}
                                  onChange={(e) =>
                                    handleReconsiderationChange(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                ></Form.Check>
                              </Form.Group>
                            </Col>
                          ))}
                        </Row>
                      </div>
                      <Button
                        variant="primary"
                        className="next action-button float-end"
                        onClick={(e) => {
                          handleSubmit(e);
                          if (validated) setShow("Description");
                        }}
                      >
                        Next
                      </Button>
                      <Button
                        variant="danger"
                        name="previous"
                        className="previous action-button-previous float-end me-3"
                        value="Previous"
                        onClick={() => setShow("A")}
                      >
                        Previous
                      </Button>
                    </fieldset>
                    <fieldset
                      className={`${
                        show === "Description" ? "d-block" : "d-none"
                      }`}
                    >
                      <div className="form-card text-start">
                        <Row>
                          <Col md="6">
                            <h3>Documents for Download:</h3>
                            {documents.map((doc, index) => (
                              <Button
                                key={index}
                                variant="danger"
                                className="mx-2 my-4"
                                onClick={() => handleDownload(doc.file)}
                              >
                                {doc.name}
                              </Button>
                            ))}
                          </Col>
                        </Row>
                        {documents.map((doc, index) => (
                          <Form.Group className="form-group" key={index}>
                            <Form.Label>{doc.name}</Form.Label>
                            <Form.Control
                              type="file"
                              required
                              onChange={(e) => handleFileChange(e, doc.index)}
                            />
                          </Form.Group>
                        ))}
                      </div>

                      <Button
                        variant="primary"
                        type="submit"
                        className="next action-button float-end"
                        onClick={(e) => {
                          handleSubmit(e);
                          if (validated) setShow("Documents");
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="danger"
                        name="previous"
                        className="previous action-button-previous float-end me-3"
                        value="Previous"
                        onClick={() => setShow("Dates")}
                      >
                        Previous
                      </Button>
                    </fieldset>
                    <fieldset
                      className={`${
                        show === "Documents" ? "d-block" : "d-none"
                      }`}
                    >
                      <div className="form-card">
                        <h2 className="text-success text-center">
                          <strong>SUCCESS !</strong>
                        </h2>
                        <br />
                        <Row className="justify-content-center">
                          <div className="col-3">
                            <Image
                              src={image1}
                              className="img-fluid"
                              alt="fit-image"
                            />
                          </div>
                        </Row>
                        <br />
                        <br />
                        <Row className="justify-content-center">
                          <div className="col-7 text-center">
                            <h5 className="purple-text text-center">
                              You Have Successfully Signed Up
                            </h5>
                          </div>
                        </Row>
                      </div>
                    </fieldset>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </div>
    </>
  );
};

export default CreateARC;
