import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Nav, Tab } from "react-bootstrap";

//image
import user4 from "../../../../assets/images/user/04.jpg";
import user5 from "../../../../assets/images/user/11.jpg";
import user6 from "../../../../assets/images/user/12.jpg";

import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const RightSidebar = () => {
  const [chatMessages, setChatMessages] = useState({
    Administrator: [],
    "Property Mgrs": [],
    "Board Member": [],
  });
  const [message, setMessage] = useState("");
  const [botMessage, setBotMessage] = useState({
    Administrator: "",
    "Property Mgrs": "",
    "Board Member": "",
  });
  const [timestamps, setTimestamps] = useState({
    Administrator: [],
    "Property Mgrs": [],
    "Board Member": [],
  });
  const [activeChat, setActiveChat] = useState(null);
  const history = useNavigate();
  const chatEndRef = useRef(null);
  const [canSend, setCanSend] = useState(false);

  // Map each chat name to its roleId
  const roleMap = {
    Administrator: 1,
    "Board Member": 2,
    "Property Mgrs": 6,
  };

  const minirightsidebar = () => {
    document.getElementById("rightSidebar").classList.toggle("right-sidebar");
    document.body.classList.toggle("right-sidebar-close");
  };

  const handleButtonClick = async (id, chatName) => {
    localStorage.setItem("messageID", id);
    setCanSend(true);
    try {
      const response = await fetch(`${apiUrl}/homeowner/chat/get`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const jsonData = await response.json();

      if (response.status === 200 && jsonData.chatMessages) {
        const chatMessage = jsonData.chatMessages.find((msg) => msg.id === id);
        if (chatMessage) {
          setBotMessage((prev) => ({
            ...prev,
            [chatName]: chatMessage.messages[0].message,
          }));
        } else {
          console.error("No message found with the given ID.");
        }
      } else {
        console.error(
          "Error fetching chat message or 'chatMessages' is undefined:",
          jsonData
        );
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const sendChat = async (e, chatName) => {
    e.preventDefault();
    try {
      let roleId = roleMap[chatName];
      let messageId = localStorage.getItem("messageID");
      let data = { roleId, messageId, message };
      const response = await fetch(`${apiUrl}/homeowner/chat/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(data),
      });
      const jsonData = await response.json();

      if (response.status === 200) {
        const currentTime = new Date();
        setChatMessages((prev) => ({
          ...prev,
          [chatName]: [...prev[chatName], message],
        }));
        setTimestamps((prev) => ({
          ...prev,
          [chatName]: [...prev[chatName], currentTime],
        }));
        setMessage("");
        localStorage.setItem("recentMessage", message);
        console.log(roleId, message);
      } else {
        console.error("Error sending chat:", jsonData);
      }
    } catch (error) {
      console.error("Send chat error:", error);
    }
  };
  const getTimeDifference = (sendTime) => {
    const now = new Date();
    const diffInMinutes = Math.floor((now - sendTime) / 60000);

    if (diffInMinutes < 1) {
      return "just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
    } else if (diffInMinutes < 1440) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else {
      const diffInDays = Math.floor(diffInMinutes / 1440);
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    }
  };

  const toggleChatModal = (chat) => {
    setActiveChat((prevChat) => (prevChat === chat ? null : chat));
  };

  useEffect(() => {
    if (activeChat && chatMessages[activeChat].length > 0) {
      chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages, activeChat]);

  return (
    <>
      <div className="right-sidebar-mini" id="rightSidebar">
        <div className="right-sidebar-panel p-0">
          <Card className="shadow-none m-0 h-100">
            <Card.Body className="px-0 pt-0">
              <div className="p-4">
                <h6 className="fw-semibold m-0">Chats</h6>
              </div>
              <Tab.Container defaultActiveKey="second">
                <Nav
                  className="nav-tabs right-sidebar-tabs"
                  id="right-sidebar-tabs"
                  role="tablist"
                >
                  <Nav.Link
                    eventKey={"second"}
                    className="text-center"
                    id="nav-groups-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-groups"
                    type="button"
                    role="tab"
                    aria-controls="nav-groups"
                    aria-selected="false"
                  >
                    <span className="text-body icon">
                      <span className="material-symbols-outlined font-size-18 mt-15">
                        group
                      </span>
                    </span>{" "}
                    <span className="h6 font-size-14">Support</span>
                  </Nav.Link>
                </Nav>

                <div className="media-height" data-scrollbar="init">
                  <Tab.Content className="right-sidebar-tabs-content">
                    <Tab.Pane eventKey={"second"}>
                      {[
                        { name: "Administrator", img: user6 },
                        { name: "Property Mgrs", img: user4 },
                        { name: "Board Member", img: user5 },
                      ].map((user, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center justify-content-between chat-tabs-content border-bottom"
                          onClick={() => toggleChatModal(user.name)}
                        >
                          <div className="d-flex align-items-center gap-3">
                            <div className="flex-shrink-0">
                              <img
                                className="rounded-circle avatar-50"
                                src={user.img}
                                alt="user-img"
                                loading="lazy"
                              />
                            </div>
                            <div>
                              <h6 className="font-size-14 mb-0 fw-semibold">
                                {user.name}
                              </h6>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
              <div className="right-sidebar-toggle bg-primary text-white mt-3 d-flex">
                <span className="material-symbols-outlined">chat</span>
              </div>
              <div className="conversion-button">
                <Button
                  onClick={() => history("/chat/index")}
                  className="btn btn-primary w-100 py-3 d-block rounded-0"
                >
                  View All Conversations
                </Button>
              </div>
              <div
                className="right-sidebar-toggle bg-primary text-white mt-3 d-flex"
                onClick={minirightsidebar}
              >
                <span className="material-symbols-outlined">chat</span>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      {["Administrator", "Property Mgrs", "Board Member"].map(
        (chatName, index) =>
          activeChat === chatName && (
            <div
              id={`chat-popup-modal-${chatName}`}
              key={index}
              className={`chat-popup-modal ${
                activeChat === chatName ? "show" : ""
              }`}
            >
              <div className="bg-primary p-3 d-flex align-items-center justify-content-between gap-3">
                <div className="d-flex align-items-center gap-3">
                  <div className="image flex-shrink-0">
                    <img
                      src={index === 0 ? user6 : index === 1 ? user4 : user5}
                      alt="img"
                      className="img-fluid avatar-45 rounded-circle object-cover"
                    />
                  </div>
                  <div className="content">
                    <h6 className="mb-0 font-size-14 text-white">{chatName}</h6>
                    <span className="d-inline-block lh-1 font-size-12 text-white">
                      <span className="d-inline-block rounded-circle bg-success border-5 p-1 align-baseline me-1"></span>
                      Available
                    </span>
                  </div>
                </div>
                <div
                  className="chat-popup-modal-close"
                  type="button"
                  onClick={() => setActiveChat(null)}
                >
                  <span className="material-symbols-outlined font-size-18 text-white">
                    close
                  </span>
                </div>
              </div>

              <div className="chat-popup-body p-3 border-bottom">
                <ul className="list-inline p-0 mb-0 chat">
                  <li className="mt-2">
                    <div className="text-start">
                      <div className="d-inline-block px-3 bg-gray-subtle chat-popup-message font-size-12 fw-medium">
                        Please click the button below about your concern
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => handleButtonClick(1, chatName)} // Fetch chat message with id 1
                        >
                          For ARC Request
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => handleButtonClick(2, chatName)} // Fetch chat message with id 2
                        >
                          For Violation
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => handleButtonClick(3, chatName)} // Fetch chat message with id3
                        >
                          For Portal Issues
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => handleButtonClick(4, chatName)} // Fetch chat message with id 4
                        >
                          For Billing
                        </button>
                        <br />
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => handleButtonClick(5, chatName)} // Fetch chat message with id 5
                        >
                          Others
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex align-items-start gap-3 my-2 mx-1 ">
                    {botMessage[chatName] && (
                      <div className="d-flex  align-items-start mb-3">
                        <div className="image flex-shrink-0">
                          <img
                            src={
                              index === 0 ? user6 : index === 1 ? user4 : user5
                            }
                            className="avatar-35 rounded-circle bg-soft-primary p-1"
                            alt="user-img"
                            loading="lazy"
                          />
                        </div>
                        <div className="d-flex align-items-start flex-column gap-2">
                          <div className="bg-light p-2 rounded-2">
                            <small className="mb-0 font-size-12 fw-medium text-body">
                              {botMessage[chatName]}
                            </small>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>

                  {chatMessages[chatName]?.map((msg, msgIndex) => (
                    <li className="mt-3" key={msgIndex}>
                      <div className="text-end">
                        <div className="d-inline-block py-2 px-3 bg-primary-subtle chat-popup-message message-right font-size-12 fw-medium">
                          {msg}
                        </div>
                        <span className="mt-1 d-block time font-size-10 text-muted ms-auto">
                          {timestamps[chatName][msgIndex] &&
                            getTimeDifference(
                              new Date(timestamps[chatName][msgIndex])
                            )}
                        </span>
                      </div>
                    </li>
                  ))}
                  <div ref={chatEndRef}></div>
                </ul>
              </div>

              <div className="chat-popup-footer p-3">
                <div className="chat-popup-form">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        sendChat(e, chatName);
                      }
                    }}
                  />
                  <Button
                    variant="primary"
                    onClick={(e) => sendChat(e, chatName)}
                    className="chat-popup-form-button btn btn-primary"
                    disabled={!canSend}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </div>
          )
      )}
    </>
  );
};

export default RightSidebar;
