import { configureStore } from "@reduxjs/toolkit";
import settingReducer from "./setting/reducers";
import { apiSlice } from "./query";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    setting: settingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
