import React from "react";
import { Card, Row, Col, Container, Form } from "react-bootstrap";

const Pledge = () => {
  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">
                    Benevento East Event - Christmas 2025
                  </h3>
                  <p className="text-white">Pledge</p>
                </div>
              </Card>
            </Col>
            <Col sm="12" lg="6">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Items to pledge</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <p>Choose below item and provide how many to bring</p>
                  <Row>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
                          <Form.Check.Input
                            type="checkbox"
                            className="form-check-input bg-primary"
                            id="customCheck-11"
                          />
                          <Form.Check.Label>Chairs</Form.Check.Label>
                          <Form.Control
                            type="number"
                            name="chairs"
                            placeholder="0"
                          />
                        </Form.Check>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
                          <Form.Check.Input
                            type="checkbox"
                            className="form-check-input bg-success"
                            id="customCheck-11"
                          />
                          <Form.Check.Label>Table</Form.Check.Label>
                          <Form.Control
                            type="number"
                            name="table"
                            placeholder="0"
                          />
                        </Form.Check>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
                          <Form.Check.Input
                            type="checkbox"
                            className="form-check-input bg-danger"
                            id="customCheck-11"
                          />
                          <Form.Check.Label>Drinks</Form.Check.Label>
                          <Form.Control
                            type="number"
                            name="drinks"
                            placeholder="0"
                          />
                        </Form.Check>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
                          <Form.Check.Input
                            type="checkbox"
                            className="form-check-input bg-warning"
                            id="customCheck-11"
                          />
                          <Form.Check.Label>Steaks</Form.Check.Label>
                          <Form.Control
                            type="number"
                            name="steaks"
                            placeholder="0"
                          />
                        </Form.Check>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
                          <Form.Check.Input
                            type="checkbox"
                            className="form-check-input bg-dark"
                            id="customCheck-11"
                          />
                          <Form.Check.Label>Juices</Form.Check.Label>
                          <Form.Control
                            type="number"
                            name="juices"
                            placeholder="0"
                          />
                        </Form.Check>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="form-group">
                        <Form.Check className="form-check form-check-inline form-checkbox form-checkbox-color me-2">
                          <Form.Check.Input
                            type="checkbox"
                            className="form-check-input bg-info"
                            id="customCheck-11"
                          />
                          <Form.Check.Label>Chicken</Form.Check.Label>
                          <Form.Control
                            type="number"
                            name="chicken"
                            placeholder="0"
                          />
                        </Form.Check>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex flex-wrap gap-1 align-items-center">
                    <button type="button" className="btn btn-primary">
                      Confirm
                    </button>

                    <button type="button" className="btn btn-danger">
                      Reset
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="12" lg="6">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">State</h4>
                  </div>
                </Card.Header>
                <Card.Body className="card-body pt-0">
                  <p>Please select services by turning on the switch</p>
                  <div class="form-check form-switch form-check-inline">
                    <Form.Check.Input
                      type="checkbox"
                      className="bg-primary"
                      id="organizer"
                      defaultChecked
                    />
                    <Form.Check.Label>Organizer</Form.Check.Label>
                  </div>
                  <div class="form-check form-switch form-check-inline">
                    <Form.Check.Input
                      type="checkbox"
                      className="bg-primary"
                      id="usher"
                      defaultChecked
                    />
                    <Form.Check.Label>Usher</Form.Check.Label>
                  </div>
                  <div class="form-check form-switch form-check-inline">
                    <Form.Check.Input
                      type="checkbox"
                      className="bg-primary"
                      id="bar"
                      defaultChecked
                    />
                    <Form.Check.Label>Bar</Form.Check.Label>
                  </div>
                  <div class="form-check form-switch form-check-inline">
                    <Form.Check.Input
                      type="checkbox"
                      className="bg-primary"
                      id="cooking-steaks"
                      defaultChecked
                    />
                    <Form.Check.Label>Cooking Steaks</Form.Check.Label>
                  </div>
                  <div class="form-check form-switch form-check-inline">
                    <Form.Check.Input
                      type="checkbox"
                      className="bg-primary"
                      id="sounds"
                      defaultChecked
                    />
                    <Form.Check.Label>Sounds</Form.Check.Label>
                  </div>
                  <div className="d-flex flex-wrap gap-1 align-items-center pt-3">
                    <button type="button" className="btn btn-primary">
                      Confirm
                    </button>

                    <button type="button" className="btn btn-danger">
                      Reset
                    </button>
                  </div>
                </Card.Body>
              </Card>
              <Col sm="12" lg="12">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Christmas Theme</h4>
                    </div>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <img
                      src="https://beneventohoa.evocodes.ph/assets/images/page-img/xmas.jpg"
                      className="img-fluid rounded"
                      alt="photo-profile"
                      loading="lazy"
                      width="500"
                    ></img>
                  </Card.Body>
                </Card>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Pledge;
