import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container, Card } from "react-bootstrap";
import { useGetApiClient } from "../../../api/useApiClient";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViolationDetails = () => {
  const [detail, setDetails] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const [comments, setComments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(Date.now());

  const violationReportId = localStorage.getItem("selectedViolationId");

  useEffect(() => {
    const getViolationDetails = async () => {
      try {
        const response = await useGetApiClient.get(
          `/homeowner/violation/get/${violationReportId}`
        );
        if (response.status === 200) {
          setDetails(response.data);
        } else {
          console.error("Error fetching Violation details:", response);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getViolationDetails();
  }, [violationReportId]);

  const getComment = async (page = 0) => {
    try {
      const response = await useGetApiClient.get(
        `/homeowner/violation/comment/get/${violationReportId}?perPage=10&currentPage=${page}`
      );
      if (response.status === 200) {
        setComments(response.data.comments);
        setTotalPages(Math.ceil(response.data.count / 10));
      } else {
        console.error("Error fetching comments data:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getComment(currentPage);
  }, [currentPage]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      if (!editorContent.trim()) {
        toast.error("Please add a comment before submitting.");
        setIsSubmitting(false);
        return;
      }

      if (!selectedFile) {
        toast.error("Please upload a file before submitting.");
        setIsSubmitting(false);
        return;
      }

      const formData = new FormData();
      formData.append("file", selectedFile);

      const uploadResponse = await useGetApiClient.post(
        "/homeowner/violation/upload/document",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (
        !uploadResponse ||
        !uploadResponse.data ||
        !uploadResponse.data.directoryPath
      ) {
        throw new Error("File upload failed. No directoryPath returned.");
      }

      const cleanComment = editorContent.replace(/<\/?[^>]+(>|$)/g, "").trim();
      const requestBody = {
        violationReportId,
        comment: cleanComment,
        documents: [
          {
            name: selectedFile.name,
            file: uploadResponse.data.directoryPath,
          },
        ],
      };
      await useGetApiClient.post(
        "/homeowner/violation/comment/save",
        requestBody
      );

      toast.success("Comment and file saved successfully!");
      setEditorContent(""); // Clear editor
      setSelectedFile(null); // Clear file selection

      setFileInputKey(Date.now()); // Reset file input
      getComment(currentPage); // Refresh comments
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to submit the comment. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  const handleCancel = () => {
    setEditorContent("");
    setSelectedFile(null);
    setFileInputKey(Date.now());
  };

  return (
    <>
      <div id="content-page" className="content-inner">
        <Container>
          {
            (detail,
            comments ? (
              <Row>
                <Col sm="12">
                  <Card
                    className="position-relative inner-page-bg bg-primary"
                    style={{ height: "150px" }}
                  >
                    <div className="inner-page-title">
                      <h3 className="text-white">Violation</h3>
                      <p className="text-white">{detail?.name || ""}</p>
                    </div>
                  </Card>
                </Col>
                <Col sm="12" lg="6">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">
                          Tracking Number: {detail?.referenceNumber || ""}
                        </h4>
                      </div>
                    </Card.Header>
                    <Card.Body className="pt-0">
                      <Row className="form-group">
                        <Col>
                          <Form.Group className="form-group">
                            <Form.Label> Violation Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              disabled
                              value={detail?.description || ""}
                            />
                          </Form.Group>
                        </Col>
                        <hr />
                        <Form.Group className="form-group">
                          <Form.Label>Activity & Comment</Form.Label>
                          <ReactQuill
                            theme="snow"
                            placeholder="Add comments here"
                            value={editorContent}
                            onChange={setEditorContent}
                          />
                        </Form.Group>

                        <Form.Group className="form-group">
                          <Form.Label>Choose File</Form.Label>
                          <Form.Control
                            type="file"
                            key={fileInputKey}
                            onChange={handleFileChange}
                          />
                        </Form.Group>

                        <Col className="mb-2">
                          <Button
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? "Submitting..." : "Submit"}
                          </Button>{" "}
                          <Button variant="danger" onClick={handleCancel}>
                            Cancel
                          </Button>
                        </Col>
                        <hr />
                        <Col>
                          <p>History</p>
                          <Row>
                            <ul className="list-inline ms-1">
                              {comments.map((comment) => (
                                <li key={comment.id} className="pt-4">
                                  <div className="d-flex align-items-center ">
                                    <div className="avatar px-2">
                                      <img
                                        src={comment.picture}
                                        alt={`${comment.name}'s profile`}
                                        className="avatar-circle bg-warning text-white"
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />{" "}
                                    </div>
                                    <div className="ms-1">
                                      <div className="d-flex text-nowrap">
                                        <h6>{comment.name}</h6>
                                        <small className="px-5 mx-5">
                                          {new Date(
                                            comment.createdAt
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "2-digit",
                                          })}
                                        </small>
                                      </div>
                                      <p className="pt-1">{comment.comment}</p>
                                      {comment.documents &&
                                        comment.documents.length > 0 && (
                                          <ul className="list-inline">
                                            {comment.documents.map((doc) => (
                                              <li key={doc.id}>
                                                <a
                                                  href={doc.file}
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                >
                                                  {doc.name}
                                                </a>
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </Row>
                          <div className="d-flex justify-content-between align-items-center mt-3">
                            <Button
                              onClick={handlePreviousPage}
                              disabled={currentPage === 0}
                            >
                              Previous
                            </Button>
                            <span>
                              Page {currentPage + 1} of {totalPages}
                            </span>
                            <Button
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages - 1}
                            >
                              Next
                            </Button>
                          </div>
                        </Col>

                        <Col></Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm="12" lg="6">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Details</h4>
                      </div>
                    </Card.Header>
                    <Card.Body className="pt-0">
                      <Form.Group className="form-group">
                        <Form.Label>Status</Form.Label>
                        <Form.Select disabled>
                          <option>{detail?.status || ""}</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>Assigned</Form.Label>
                        <Form.Control
                          type="text"
                          value={detail?.assigned || ""}
                          disabled
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Date Filed</Form.Label>
                        <Form.Control
                          type="date"
                          value={detail?.createdAt || ""}
                          disabled
                        ></Form.Control>
                      </Form.Group>

                      <Form.Group className="form-group">
                        <Form.Label>Watchers</Form.Label>
                        {detail?.watchers.map((watcher, index) => (
                          <Form.Check
                            key={index}
                            label={watcher}
                            type="checkbox"
                            checked
                            disabled
                          ></Form.Check>
                        ))}
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>File Input</Form.Label>
                        <Form.Control type="file"></Form.Control>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : (
              <p>Loading...</p>
            ))
          }
        </Container>
        <ToastContainer />
      </div>
    </>
  );
};

export default ViolationDetails;
