import {
  SaveViolationModel,
  ViolationsListModel,
} from "../../../model/violations/violationsmodel";
import { apiSlice, errorHandler, ErrorResponseModel } from "..";
import { violationTags } from "../tags";

export const endpoints = {
  list: "/homeowner/violation/list",
  save: "/homeowner/violation/save",
};

export const violationSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getViolationList: builder.query<ViolationsListModel, void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      providesTags: [violationTags[0]],
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
    saveViolation: builder.mutation<void, SaveViolationModel>({
      query: (body) => ({
        url: endpoints.save,
        method: "POST",
        body,
      }),
      transformErrorResponse: (response: ErrorResponseModel) =>
        errorHandler(response),
    }),
  }),
});

export const { useGetViolationListQuery, useSaveViolationMutation } =
  violationSlice;
