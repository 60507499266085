import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Nav,
  Tab,
  OverlayTrigger,
  Tooltip,
  CardBody,
  Button,
} from "react-bootstrap";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import ReactFsLightbox from "fslightbox-react";

// images
import img1 from "../../../assets/images/page-img/fun.jpg";

import user1 from "../../../assets/images/user/1.jpg";
import user01 from "../../../assets/images/user/01.jpg";
import user02 from "../../../assets/images/user/02.jpg";
import user03 from "../../../assets/images/user/03.jpg";
import g1 from "../../../assets/images/page-img/g1.jpg";
import g2 from "../../../assets/images/page-img/g2.jpg";
import g3 from "../../../assets/images/page-img/g3.jpg";
import g4 from "../../../assets/images/page-img/g4.jpg";
import g5 from "../../../assets/images/page-img/g5.jpg";
import g6 from "../../../assets/images/page-img/g6.jpg";
import g7 from "../../../assets/images/page-img/g7.jpg";
import g8 from "../../../assets/images/page-img/g8.jpg";
import g9 from "../../../assets/images/page-img/g9.jpg";
import img51 from "../../../assets/images/page-img/51.jpg";
import img52 from "../../../assets/images/page-img/52.jpg";
import img53 from "../../../assets/images/page-img/53.jpg";
import img54 from "../../../assets/images/page-img/54.jpg";
import img55 from "../../../assets/images/page-img/55.jpg";
import img56 from "../../../assets/images/page-img/56.jpg";
import img57 from "../../../assets/images/page-img/57.jpg";
import img58 from "../../../assets/images/page-img/58.jpg";
import img59 from "../../../assets/images/page-img/59.jpg";
import img60 from "../../../assets/images/page-img/60.jpg";
import img61 from "../../../assets/images/page-img/61.jpg";
import img62 from "../../../assets/images/page-img/62.jpg";
import img64 from "../../../assets/images/page-img/64.jpg";
import img65 from "../../../assets/images/page-img/65.jpg";
import img63 from "../../../assets/images/page-img/63.jpg";
import pageBgImg from "../../../assets/images/page-img/profile-bg1.jpg";

import mountain from "../../../assets/images/page-img/mountain.webp";
import pizza from "../../../assets/images/page-img/pizza.webp";
import busImg from "../../../assets/images/page-img/bus.webp";
import boyImg from "../../../assets/images/page-img/boy.webp";
import img11 from "../../../assets/images/page-img/fd.webp";

import coin from "../../../assets/images/gamipress/coin.svg";
import credit from "../../../assets/images/gamipress/credit.svg";
import gems from "../../../assets/images/gamipress/gems.svg";

//full calender
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import * as moment from "moment";

//flatpickr
import Flatpickr from "react-flatpickr";

// Fslightbox plugin
const FsLightbox = ReactFsLightbox.default
  ? ReactFsLightbox.default
  : ReactFsLightbox;

const HoaProfile = () => {
  useEffect(() => {
    document.body.classList.add("profile-page");
    return () => {
      document.body.classList.remove("profile-page");
    };
  });

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  const aboutData = [
    {
      title: "About Me:",
      data: "Hi, I’m James, I’m 36 and I work as a Digital Designer for the “Daydreams” Agency in Pier 56",
    },
    {
      title: "Email:",
      data: "Bnijohn@gmail.com",
    },
    {
      title: "Mobile:",
      data: "(001) 4544 565 456",
    },
    {
      title: "Address:",
      data: "United States of America",
    },
    {
      title: "Social Link:",
      data: "www.bootstrap.com",
    },
    {
      title: "Birth Date:",
      data: "24 January",
    },
    {
      title: "Birth Year:",
      data: "1994",
    },
    {
      title: "Birthplace:",
      data: "Austin, Texas, USA",
    },
    {
      title: "Lives in:",
      data: "San Francisco, California, USA",
    },
    {
      title: "Gender:",
      data: "Female",
    },
    {
      title: "Interested in:",
      data: "Designing",
    },
    {
      title: "Language:",
      data: "English, French",
    },
    {
      title: "Joined:",
      data: "April 31st, 2014",
    },
    {
      title: "Status:",
      data: "Married",
    },
    {
      title: "Phone Number:",
      data: "(044) 555 - 4369 - 8957",
    },
    {
      title: "Political Incline:",
      data: "Democrat",
    },
  ];

  const linkData = [
    {
      title: "Website:",
      data: "www.bootstrap.com",
    },
    {
      title: "Social Link:",
      data: "www.bootstrap.com",
    },
  ];

  const events = [
    {
      title: "5:30a Click for Google",
      url: "http://google.com/",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-20, "days")
        .format("YYYY-MM-DD"),
      color: "#50b5ff",
    },
    {
      title: "5:30a All Day Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-18, "days")
        .format("YYYY-MM-DD"),
      color: "#a09e9e",
    },
    {
      title: "5:30a Long Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-16, "days")
        .format("YYYY-MM-DD"),
      end: moment(new Date(), "YYYY-MM-DD")
        .add(-13, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-14, "days")
        .format("YYYY-MM-DD"),
      color: "#ffba68",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-12, "days")
        .format("YYYY-MM-DD"),
      color: "#d592ff ",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-10, "days")
        .format("YYYY-MM-DD"),
      color: "#ff9b8a",
    },
    {
      title: "5:30a Birthday Party",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-8, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Meeting",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-6, "days")
        .format("YYYY-MM-DD"),
      color: "#a09e9e",
    },
    {
      title: "5:30a Birthday Party",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-5, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Birthday Party",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(-2, "days")
        .format("YYYY-MM-DD"),
      color: "#ff9b8a ",
    },

    {
      title: "5:30a Meeting",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#ff9b8a",
    },
    {
      title: "5:30a Click for Google",
      url: "http://google.com/",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#d592ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
    {
      title: "5:30a Birthday Party",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#f4a965",
    },
    {
      title: "5:30a Doctor Meeting",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(0, "days")
        .format("YYYY-MM-DD"),
      color: "#f4a965",
    },
    {
      title: "5:30a All Day Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD"),
      color: " #50b5ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(8, "days")
        .format("YYYY-MM-DD"),
      color: " #50b5ff",
    },
    {
      groupId: "999",
      title: "5:30a Repeating Event",
      start: moment(new Date(), "YYYY-MM-DD")
        .add(10, "days")
        .format("YYYY-MM-DD"),
      color: "#49f0d3",
    },
  ];

  const data = [
    {
      control: "INV00001",
      Description: "Monthly Fee",
      DateIssued: "	05-1-24",
      DateDue: "	05-15-24",
      Amount: "$300	",
      Status: "Unpaid",
    },
  ];

  const request = [
    {
      Reference: "000001",
      CreatedBy: "Thomas Hadden",
      Type: "ARC",
      Description: "Remodel of parking",
      DateCreated: "03-04-24",
      DateClosed: "04-04-24",
      Status: "Closed",
    },
    {
      Reference: "000002",
      CreatedBy: "5000 Moretto Court",
      Type: "ARC",
      Description: "Roof Fixes",
      DateCreated: "05-01-24",
      DateClosed: "--------",
      Status: "Ongoing",
    },
  ];

  const [Add, setAdd] = useState(request);
  const [addFormData] = useState({
    Reference: "",
    CreatedBy: "",
    Type: "",
    Description: "",
    DateCreated: "",
    DateClosed: "",
    Status: "",
  });

  const addNewValue = () => {
    setAdd(Add.concat(request[1]));
  };

  const handleAddFormChanges = (event) => {
    event.preventDefault();
    const Reference = event.target.closest("tr").getAttribute("dataReference");
    const tdElem = event.target.closest("tr").querySelectorAll("td");
    const obj = {
      Reference: Reference,
    };
    Array.from(tdElem, (elem) => {
      if (elem.getAttribute("name") !== null) {
        obj[elem.getAttribute("name")] = elem.innerText;
      }
      return null;
    });
    const newArrIndex = Add.findIndex((item) => {
      if (item.Reference === Reference) {
        return item;
      } else {
        return null;
      }
    });
    Add[newArrIndex] = obj;
    setAdd(Add);
  };

  const handleAddFormSubmit = (event) => {
    event.preventDefault();

    let newContact = {
      Reference: `000${Add.length + 1}`,
      Name: addFormData.Name,
      Age: addFormData.Age,
      Company: addFormData.Company,
      Country: addFormData.Country,
      City: addFormData.City,
    };
    const newContacts = [...Add, newContact];
    setAdd(newContacts);
  };

  return (
    <>
      <div id="content-page" className="content-inner">
        <FsLightbox
          toggler={imageController.toggler}
          sources={[
            g1,
            g2,
            g3,
            g4,
            g5,
            g6,
            g7,
            g8,
            g9,
            img1,
            boyImg,
            busImg,
            img11,
            mountain,
            pizza,
            img51,
            img52,
            img53,
            img54,
            img55,
            img56,
            img57,
            img58,
            img59,
            img60,
            img61,
            img62,
            img63,
            img64,
            img65,
            img51,
            img52,
            img53,
            img54,
            img55,
            img56,
            img57,
            img58,
            img51,
            img52,
            img53,
            img54,
            img55,
            img56,
            img57,
            img58,
            img59,
            img60,
          ]}
          slide={imageController.slide}
        />
        <Container className="position-relative p-0">
          <div
            className="header-cover-img"
            style={{
              backgroundImage: `url(${pageBgImg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </Container>
        <Container>
          <Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Col sm={12}>
                <Card className="profile-box">
                  <Card.Body>
                    <Row className="align-items-center item-header-content">
                      <Col lg={4} className="profile-left"></Col>
                      <Col lg={4} className="text-center profile-center">
                        <div className="header-avatar position-relative d-inline-block">
                          <span className="change-profile-image bg-primary rounded-pill">
                            <span className="material-symbols-outlined text-white font-size-16">
                              photo_camera
                            </span>
                          </span>
                          <img
                            src={user1}
                            alt="user"
                            className="avatar-150 border border-4 border-white rounded-3"
                          />
                          <span className="badge bg-success fw-500 letter-spacing-1 chat-status">
                            online
                          </span>
                        </div>
                        <h5 className="d-flex align-items-center justify-content-center gap-1 mb-2">
                          Thomas Hadden{" "}
                          <span className="badge  bg-primary rounded-pill material-symbols-outlined font-size-14 p-0 custom-done">
                            done
                          </span>
                        </h5>
                        <ul className="d-flex align-items-center justify-content-center gap-3 list-inline p-0 m-0">
                          <li className="d-flex align-items-center gap-1">
                            <h6 className="material-symbols-outlined font-size-14">
                              location_on
                            </h6>
                            <span className="font-size-14 text-uppercase fw-500">
                              San Giovanni
                            </span>
                          </li>
                          <li className="d-flex align-items-center gap-1">
                            <h6 className="material-symbols-outlined font-size-14">
                              globe_asia
                            </h6>
                            <Link
                              to="#"
                              className="font-size-14 fw-500 text-body"
                            >
                              hadlotechnologies.com/
                            </Link>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body className="p-0">
                    <div className="user-tabing item-list-tabs">
                      <Nav
                        as="ul"
                        variant="pills"
                        className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0 rounded"
                      >
                        <Nav.Item as="li" className="col-12 ">
                          <Nav.Link
                            eventKey="first"
                            role="button"
                            className="d-flex flex-md-column align-items-center flex-row justify-content-center gap-2"
                          >
                            <span className="icon rounded-3">
                              <span className="material-symbols-outlined">
                                person
                              </span>
                            </span>{" "}
                            <p className="mb-0 mt-0 mt-md-3">Profile</p>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="about1"
                    >
                      <Row>
                        <Col md={4}>
                          <Card>
                            <Card.Body>
                              <Nav
                                variant="pills"
                                className=" basic-info-items list-inline d-block p-0 m-0"
                              >
                                <Nav.Item>
                                  <Nav.Link to="#" eventKey="about1">
                                    Contact and Basic Info
                                  </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                  <Nav.Link to="#" eventKey="about3">
                                    Family or Co-owners
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link to="#" eventKey="about4">
                                    Work and Education
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link to="#" eventKey="about5">
                                    Places You've Lived
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col md={8} className=" ps-4">
                          <Card>
                            <Card.Body>
                              <Tab.Content>
                                <Tab.Pane eventKey="about1">
                                  <h4>Personal Info</h4>
                                  <hr />
                                  <div className="table-responsive">
                                    <table className="table profile-table">
                                      <tbody>
                                        {aboutData.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>
                                                <h6>{item.title}</h6>
                                              </td>
                                              <td>
                                                <p className="mb-0">
                                                  {item.data}
                                                </p>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                  <h4 className="mt-2">
                                    Websites and Social Links
                                  </h4>
                                  <hr />
                                  <div className="table-responsive">
                                    <table className="table profile-table">
                                      <tbody>
                                        {linkData.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>
                                                <h6>{item.title}</h6>
                                              </td>
                                              <td>
                                                <p className="mb-0">
                                                  {item.data}
                                                </p>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="about2">
                                  <h4 className="mt-2">
                                    Hobbies and Interests
                                  </h4>
                                  <hr />
                                  <h6 className="mb-1">Hobbies:</h6>
                                  <p>
                                    Hi, I’m Bni, I’m 26 and I work as a Web
                                    Designer for the iqonicdesign.I like to ride
                                    the bike to work, swimming, and working out.
                                    I also like reading design magazines, go to
                                    museums, and binge watching a good tv show
                                    while it’s raining outside.
                                  </p>
                                  <h6 className="mt-2 mb-1">
                                    Favourite TV Shows:
                                  </h6>
                                  <p>
                                    Breaking Good, RedDevil, People of Interest,
                                    The Running Dead, Found, American Guy.
                                  </p>
                                  <h6 className="mt-2 mb-1">
                                    Favourite Movies:
                                  </h6>
                                  <p>
                                    Idiocratic, The Scarred Wizard and the Fire
                                    Crown, Crime Squad, Ferrum Man.
                                  </p>
                                  <h6 className="mt-2 mb-1">
                                    Favourite Games:
                                  </h6>
                                  <p>
                                    The First of Us, Assassin’s Squad, Dark
                                    Assylum, NMAK16, Last Cause 4, Grand Snatch
                                    Auto.
                                  </p>
                                  <h6 className="mt-2 mb-1">
                                    Favourite Music Bands / Artists:
                                  </h6>
                                  <p>
                                    Iron Maid, DC/AC, Megablow, The Ill, Kung
                                    Fighters, System of a Revenge.
                                  </p>
                                  <h6 className="mt-2 mb-1">
                                    Favourite Books:
                                  </h6>
                                  <p>
                                    The Crime of the Century, Egiptian Mythology
                                    101, The Scarred Wizard, Lord of the Wings,
                                    Amongst Gods, The Oracle, A Tale of Air and
                                    Water.
                                  </p>
                                  <h6 className="mt-2 mb-1">
                                    Favourite Writers:
                                  </h6>
                                  <p>
                                    Martin T. Georgeston, Jhonathan R. Token,
                                    Ivana Rowle, Alexandria Platt, Marcus Roth.
                                  </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="about3">
                                  <h4 className="mt-3 mb-3">Family Members</h4>
                                  <ul className="suggestions-lists m-0 p-0">
                                    <li className="d-flex mb-4 align-items-center">
                                      <div className="user-img img-fluid">
                                        <span className="material-symbols-outlined md-18">
                                          add
                                        </span>
                                      </div>
                                      <div className="media-support-info ms-3">
                                        <h6>Add Family Members</h6>
                                      </div>
                                    </li>
                                    <li className="d-flex mb-4 align-items-center justify-content-between">
                                      <div className="user-img img-fluid">
                                        <img
                                          loading="lazy"
                                          src={user01}
                                          alt="story1"
                                          className="rounded-circle avatar-40"
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex justify-content-between">
                                          <div className="ms-3">
                                            <h6>Paul Molive</h6>
                                            <p className="mb-0">Brothe</p>
                                          </div>
                                          <div className="edit-relation">
                                            <Link
                                              to="#"
                                              className="d-flex align-items-center"
                                            >
                                              <span className="material-symbols-outlined me-2 md-18">
                                                edit
                                              </span>
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="d-flex justify-content-between mb-4  align-items-center">
                                      <div className="user-img img-fluid">
                                        <img
                                          loading="lazy"
                                          src={user02}
                                          alt="story-img"
                                          className="rounded-circle avatar-40"
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                          <div className=" ms-3">
                                            <h6>Anna Mull</h6>
                                            <p className="mb-0">Sister</p>
                                          </div>
                                          <div className="edit-relation">
                                            <Link
                                              to="#"
                                              className="d-flex align-items-center"
                                            >
                                              <span className="material-symbols-outlined me-2 md-18">
                                                edit
                                              </span>
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="d-flex mb-4 align-items-center justify-content-between">
                                      <div className="user-img img-fluid">
                                        <img
                                          loading="lazy"
                                          src={user03}
                                          alt="story-img"
                                          className="rounded-circle avatar-40"
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex justify-content-between">
                                          <div className="ms-3">
                                            <h6>Paige Turner</h6>
                                            <p className="mb-0">Cousin</p>
                                          </div>
                                          <div className="edit-relation">
                                            <Link
                                              to="#"
                                              className="d-flex align-items-center"
                                            >
                                              <span className="material-symbols-outlined me-2 md-18">
                                                edit
                                              </span>
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="about4">
                                  <h4 className="mb-3">Work</h4>
                                  <ul className="suggestions-lists m-0 p-0">
                                    <li className="d-flex justify-content-between mb-4  align-items-center">
                                      <div className="user-img img-fluid">
                                        <span className="material-symbols-outlined md-18">
                                          add
                                        </span>
                                      </div>
                                      <div className="ms-3">
                                        <h6>Add Work Place</h6>
                                      </div>
                                    </li>
                                    <li className="d-flex mb-4 align-items-center justify-content-between">
                                      <div className="user-img img-fluid">
                                        <img
                                          loading="lazy"
                                          src={user01}
                                          alt="story-img"
                                          className="rounded-circle avatar-40"
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex justify-content-between">
                                          <div className="ms-3">
                                            <h6>Themeforest</h6>
                                            <p className="mb-0">Web Designer</p>
                                          </div>
                                          <div className="edit-relation">
                                            <Link
                                              to="#"
                                              className="d-flex align-items-center"
                                            >
                                              <span className="material-symbols-outlined me-2 md-18">
                                                edit
                                              </span>
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="d-flex mb-4 align-items-center justify-content-between">
                                      <div className="user-img img-fluid">
                                        <img
                                          loading="lazy"
                                          src={user02}
                                          alt="story-img"
                                          className="rounded-circle avatar-40"
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                          <div className="ms-3">
                                            <h6>iqonicdesign</h6>
                                            <p className="mb-0">
                                              Web Developer
                                            </p>
                                          </div>
                                          <div className="edit-relation">
                                            <Link
                                              to="#"
                                              className="d-flex align-items-center"
                                            >
                                              <span className="material-symbols-outlined me-2 md-18">
                                                edit
                                              </span>
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="d-flex mb-4 align-items-center justify-content-between">
                                      <div className="user-img img-fluid">
                                        <img
                                          loading="lazy"
                                          src={user03}
                                          alt="story-img"
                                          className="rounded-circle avatar-40"
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                          <div className="ms-3">
                                            <h6>W3school</h6>
                                            <p className="mb-0">Designer</p>
                                          </div>
                                          <div className="edit-relation">
                                            <Link
                                              to="#"
                                              className="d-flex align-items-center"
                                            >
                                              <span className="material-symbols-outlined me-2 md-18">
                                                edit
                                              </span>
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                  <h4 className="mb-3">Professional Skills</h4>
                                  <ul className="suggestions-lists m-0 p-0">
                                    <li className="d-flex mb-4 align-items-center">
                                      <div className="user-img img-fluid">
                                        <span className="material-symbols-outlined md-18">
                                          add
                                        </span>
                                      </div>
                                      <div className="ms-3">
                                        <h6>Add Professional Skills</h6>
                                      </div>
                                    </li>
                                  </ul>
                                  <h4 className="mt-3 mb-3">College</h4>
                                  <ul className="suggestions-lists m-0 p-0">
                                    <li className="d-flex mb-4 align-items-center">
                                      <div className="user-img img-fluid">
                                        <span className="material-symbols-outlined md-18">
                                          add
                                        </span>
                                      </div>
                                      <div className="ms-3">
                                        <h6>Add College</h6>
                                      </div>
                                    </li>
                                    <li className="d-flex mb-4 align-items-center">
                                      <div className="user-img img-fluid">
                                        <img
                                          loading="lazy"
                                          src={user01}
                                          alt="story-img"
                                          className="rounded-circle avatar-40"
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                          <div className="ms-3">
                                            <h6>Lorem ipsum</h6>
                                            <p className="mb-0">USA</p>
                                          </div>
                                          <div className="edit-relation">
                                            <Link
                                              to="#"
                                              className="d-flex align-items-center"
                                            >
                                              <span className="material-symbols-outlined me-2 md-18">
                                                edit
                                              </span>
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="about5">
                                  <h4 className="mb-3">
                                    Current City and Hometown
                                  </h4>
                                  <ul className="suggestions-lists m-0 p-0">
                                    <li className="d-flex mb-4 align-items-center justify-content-between">
                                      <div className="user-img img-fluid">
                                        <img
                                          loading="lazy"
                                          src={user01}
                                          alt="story-img"
                                          className="rounded-circle avatar-40"
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                          <div className="ms-3">
                                            <h6>Georgia</h6>
                                            <p className="mb-0">
                                              Georgia State
                                            </p>
                                          </div>
                                          <div className="edit-relation">
                                            <Link
                                              to="#"
                                              className="d-flex align-items-center"
                                            >
                                              <span className="material-symbols-outlined me-2 md-18">
                                                edit
                                              </span>
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="d-flex mb-4 align-items-center justify-content-between">
                                      <div className="user-img img-fluid">
                                        <img
                                          loading="lazy"
                                          src={user02}
                                          alt="story-img"
                                          className="rounded-circle avatar-40"
                                        />
                                      </div>
                                      <div className="w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                          <div className="ms-3">
                                            <h6>Atlanta</h6>
                                            <p className="mb-0">Atlanta City</p>
                                          </div>
                                          <div className="edit-relation">
                                            <Link
                                              to="#"
                                              className="d-flex align-items-center"
                                            >
                                              <span className="material-symbols-outlined me-2 md-18">
                                                edit
                                              </span>
                                              Edit
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                  <h4 className="mt-3 mb-3">
                                    Other Places Lived
                                  </h4>
                                  <ul className="suggestions-lists m-0 p-0">
                                    <li className="d-flex mb-4 align-items-center">
                                      <div className="user-img img-fluid">
                                        <span className="material-symbols-outlined md-18">
                                          add
                                        </span>
                                      </div>
                                      <div className="ms-3">
                                        <h6>Add Place</h6>
                                      </div>
                                    </li>
                                  </ul>
                                </Tab.Pane>
                              </Tab.Content>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HoaProfile;
