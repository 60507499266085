import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const BillStatus = () => {
  const data = [
    {
      control: "INV00001",
      Description: "Monthly Fee",
      DateIssued: "	05-1-24",
      DateDue: "	05-15-24",
      Amount: "$300	",
      Status: "Unpaid",
    },
  ];

  return (
    <>
      <div Reference="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">Monthly Bills History</h3>
                  <p className="text-white">lorem ipsum</p>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card>
                <div>
                  <h3 className="card-header text-center font-weight-bold text-uppercase py-4">
                    Bill Status
                  </h3>
                </div>
                <Card.Body className="pt-0">
                  <div Reference="table" className="table-editable">
                    <table className="table table-bordered table-responsive-md table-striped text-center">
                      <thead>
                        <tr>
                          <th>Control #</th>
                          <th>Description</th>
                          <th>Date Issued</th>
                          <th>Date Due</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, idx) => (
                          <tr key={idx}>
                            <td>{item.control}</td>
                            <td>{item.Description}</td>
                            <td>{item.DateIssued}</td>
                            <td>{item.DateDue}</td>
                            <td>{item.Amount}</td>
                            <td>{item.Status}</td>

                            <td>
                              <a href="">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip>Pay</Tooltip>}
                                >
                                  <i className="material-symbols-outlined me-1 md-18 text-primary">
                                    paid
                                  </i>
                                </OverlayTrigger>{" "}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BillStatus;
