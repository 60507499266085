import { createApi } from "@reduxjs/toolkit/query/react";
import { BaseQueryFn, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { homeownerTags, violationTags } from "./tags";

export interface ErrorResponseModel {
  data: {
    message: string;
  };
  status: number;
  originalStatus: number;
}

export const errorHandler = (response: ErrorResponseModel) => {
  const errorStatus: number =
    typeof response.status === "string"
      ? response.originalStatus
      : response.status;
  switch (errorStatus) {
    case 401:
      return { data: "Unathorized!", status: response.status };
    case 404:
      return { data: "Page Not Found!", status: response.status };
    case 500:
      return { data: "Internal Error!", status: response.status };
    case 413:
      return { data: "File/Image size is too large", status: response.status };
    // case 422:
    //   return {
    //     data:
    //       response.data.data.length > 0
    //         ? response.data.data[0].msg
    //         : response.data.msg,
    //     status: response.status,
    //   };
    default:
      return {
        data: response?.data?.message ?? "Something went wrong.",
        status: response.status,
      };
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("token");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQuery,
  tagTypes: [...homeownerTags, ...violationTags],
  endpoints: (builder) => ({
    uploadFile: builder.mutation<
      { directoryPath: string },
      { endpoint: string; file: File; formKey: string }
    >({
      query: (param) => {
        const formDataBody = new FormData();

        if (param) {
          formDataBody.append(param.formKey, param.file);
        }

        return {
          url: param.endpoint,
          method: "POST",
          body: formDataBody,
          formData: true,
        };
      },
      transformErrorResponse: (response: ErrorResponseModel) => {
        return errorHandler(response);
      },
    }),
  }),
});

export const { useUploadFileMutation } = apiSlice;
