import { HomeownerListModel } from "../../../model/homeowner";
import { apiSlice } from "..";
import { homeownerTags } from "../tags";

export const endpoints = {
  list: "/homeowner/homeowners/list",
};

export const homeownerSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHomeownerList: builder.query<HomeownerListModel, void>({
      query: () => ({
        url: endpoints.list,
        method: "GET",
      }),
      providesTags: [homeownerTags[0]],
    }),
  }),
});

export const { useGetHomeownerListQuery } = homeownerSlice;
