import { createContext, useContext, useEffect, useState } from "react";

export const AuthContext = createContext();

const savedAuthUserData = JSON.parse(localStorage.getItem('savedAuthUserData'));

export const useAuthContext = () => {
	return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
	const [authUser, setAuthUser] = useState(() => {
        if (savedAuthUserData) {
            return savedAuthUserData;
        }
        return null;
    });

    useEffect(() => {
        localStorage.setItem("savedAuthUserData", JSON.stringify(authUser));
    }, [authUser])

	return <AuthContext.Provider value={{ authUser, setAuthUser }}>{children}</AuthContext.Provider>;
};
