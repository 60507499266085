import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { useGetApiClient } from "../../../api/useApiClient";

const ViolationTimeline = () => {
  const [timelineData, setTimelineData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTimelineData = async () => {
      const id = localStorage.getItem("selectedViolationId");

      try {
        const response = await useGetApiClient.get(
          `/homeowner/violation/get-timeline/${id}`
        );
        if (response.status === 200) {
          setTimelineData(response.data);
        } else {
          console.error("Error fetching timeline data:", response);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchTimelineData();
  }, []);

  return (
    <div id="content-page" className="content-inner">
      <Container>
        {timelineData ? (
          <Row className="justify-content-center">
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">
                    Timeline Updated For: ARC Request
                  </h3>
                  <p className="text-white">{timelineData.description}</p>
                </div>
              </Card>
            </Col>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3>{timelineData.name}</h3>
                  <br />
                  <Link to="/dashboard/app/violation-requests">
                    <Button className="my-2 ">Back</Button>
                  </Link>
                </div>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Timeline</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <ul className="iq-timeline ms-1">
                    {timelineData.timeline.map((item) => (
                      <li>
                        <Link to="/dashboard/app/violation-requests/timeline/details">
                          <div className="timeline-dots"></div>
                        </Link>
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="mb-1">{item.title}</h6>
                          <small> {new Date(item.date).toLocaleString()}</small>
                        </div>
                        <div className="d-inline-block w-100">
                          <p>{item.description}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ) : (
          <p>Loading timeline data...</p>
        )}
      </Container>
    </div>
  );
};

export default ViolationTimeline;
