import React, { ChangeEvent, useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import _ from "lodash";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
  Container,
  Card,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import {
  useGetViolationListQuery,
  useSaveViolationMutation,
} from "../../../store/query/violations/violationsquery";
import { useUploadFileMutation } from "../../../store/query";
import { SaveViolationModel } from "../../../model/violations/violationsmodel";
import useFileView from "../../../hooks/useFileView";
import { toast } from "react-toastify";

type Props = {};

const CreateViolation = (props: Props) => {
  const [show, setShow] = useState("A");
  const documentsRef = useRef<{ name: string; file: string }[]>([]);
  const [viewDoc, handleToggleDoc] = useFileView();
  const formValidationSchema = Yup.object().shape({
    violatorId: Yup.number().required("Please select a valid address."),
    violationId: Yup.number().required("Please select a violation type."),
    description: Yup.string().required("Please provide a description."),
    dateSeen: Yup.string().required("Please provide a valid date."),
    documents: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string(),
          file: Yup.string(),
        })
      )
      .min(1, "Please provide a document."),
  });

  const {
    data,
    isLoading: dataLoading,
    error: dataError,
  } = useGetViolationListQuery();
  const [
    uploadMutate,
    { isLoading: uploadLoading, reset: uploadReset, error: uploadError },
  ] = useUploadFileMutation();
  const [
    saveViolation,
    {
      reset: saveReset,
      error: saveError,
      isSuccess: saveSuccess,
      isLoading: saveLoading,
    },
  ] = useSaveViolationMutation();
  const newDate = new Date().toISOString().split("T");

  const violatorsConfig = data ? data.violators : [];
  const violationsConfig = data ? data.violations : [];

  const submitFormFn = async (form: SaveViolationModel) => {
    await saveViolation(form);
    setShow("Information");
  };

  const handleUpload = async (
    value: File,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean
    ) => void,
    setFieldError: (field: string, message: string | undefined) => void
  ) => {
    const data = await uploadMutate({
      endpoint: "/homeowner/arc/upload/document",
      file: value,
      formKey: "file",
    }).unwrap();

    if (data) {
      setFieldValue(
        "documents",
        [
          ...documentsRef.current,
          { name: value.name, file: data.directoryPath },
        ],
        true
      );
      documentsRef.current = [
        ...documentsRef.current,
        { name: value.name, file: data.directoryPath },
      ];
      setFieldError("documents", "");
    }
  };

  if (saveSuccess) {
    saveReset();

    toast.success("Violation saved successfully!", { toastId: "success" });
  }

  if (saveError) {
    saveReset();
    toast.error("Failed to save violation", { toastId: "error" });
  }

  if (dataError) {
    toast.error("Error fetching", { toastId: "error" });
  }

  if (uploadError) {
    uploadReset();

    toast.error("Failed to upload", { toastId: "error" });
  }

  return (
    <>
      {viewDoc.open && (
        <Modal
          show={viewDoc.open}
          onHide={() => handleToggleDoc(false, "", undefined)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {viewDoc.filename}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "65vh", overflowY: "auto" }}>
            <DocViewer
              documents={[
                {
                  uri: viewDoc.path.includes(process.env.REACT_APP_MAIN_URL)
                    ? viewDoc.path
                    : `${process.env.REACT_APP_MAIN_URL}${viewDoc.path}`,
                },
              ]}
              pluginRenderers={DocViewerRenderers}
            />
          </Modal.Body>
        </Modal>
      )}
      <div id="content-page" className="content-inner">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">Violations</h3>
                  <p className="text-white">Create Violations</p>
                </div>
              </Card>
            </Col>
            <Col sm={12} lg={12}>
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Violations Form</h4>
                  </div>
                </Card.Header>
                <Card.Body className="pt-0">
                  <ul id="top-tab-list" className="p-0 row list-inline">
                    <li
                      className={` ${
                        show === "Description" ? "active done" : ""
                      } ${show === "Dates" ? "active done" : ""} ${
                        show === "A" ? "active " : ""
                      } col-lg-3 col-md-6 text-start mb-2 active`}
                      id="Information"
                    >
                      <Link to="#">
                        <i className="material-symbols-outlined">description</i>
                        <span>Information</span>
                      </Link>
                    </li>
                    <li
                      id="confirm"
                      className={` ${
                        show === "Information" ? "active done" : ""
                      } col-lg-3 col-md-6 mb-2 text-start`}
                    >
                      <Link to="#">
                        <i className="material-symbols-outlined">done</i>
                        <span>Finish</span>
                      </Link>
                    </li>
                  </ul>
                  <Formik
                    validationSchema={formValidationSchema}
                    initialValues={{
                      violatorId: "",
                      violationId: undefined,
                      description: "",
                      // dateSeen: `${newDate[0]} 00:00:00}`,
                      dateSeen:
                        new Date().toISOString().split("T")[0] + " 00:00:00",
                      documents: [],
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      const form: SaveViolationModel = {
                        dateSeen: values.dateSeen,
                        description: values.description,
                        violationId: parseInt(values.violationId),
                        documents: values.documents,
                        violatorId: parseInt(values.violatorId),
                      };

                      submitFormFn(form);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                      setFieldError,
                      isValid,
                    }) => (
                      <Form onSubmit={handleSubmit} noValidate>
                        {JSON.stringify(isValid)}
                        <Row>
                          <div className="col-7">
                            <h3 className="mb-4">Request & Dates</h3>
                          </div>
                        </Row>
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <Form.Label>Home Address *</Form.Label>
                              <Form.Select
                                name="violatorId"
                                value={values.violatorId}
                                onChange={handleChange}
                                isInvalid={!_.isEmpty(errors.violatorId)}
                              >
                                <option value="">Select House Address</option>
                                {violatorsConfig.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.houseName}
                                  </option>
                                ))}
                              </Form.Select>
                              {errors.violatorId && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.violatorId as string}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <Form.Label>Date Seen *</Form.Label>
                              <Form.Control
                                type="datetime-local"
                                name="dateSeen"
                                value={values.dateSeen}
                                onChange={handleChange}
                                isInvalid={!_.isEmpty(errors.dateSeen)}
                              />
                              {errors.dateSeen && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.dateSeen as string}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <Form.Label>Violation Type *</Form.Label>
                              <Form.Select
                                name="violationId"
                                value={values.violationId}
                                onChange={handleChange}
                                isInvalid={!_.isEmpty(errors.violationId)}
                              >
                                <option value="">Select Violation Type</option>
                                {violationsConfig.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </Form.Select>
                              {errors.violationId && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.violationId as string}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group>
                              <Form.Label>Upload Documents *</Form.Label>
                              <Form.Control
                                type="file"
                                name="documents"
                                isInvalid={!_.isEmpty(errors.documents)}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleUpload(
                                    e.target.files[0],
                                    setFieldValue,
                                    setFieldError
                                  )
                                }
                              />
                              {errors.documents && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.documents as string}
                                </Form.Control.Feedback>
                              )}
                              <div className="mt-3">
                                {values.documents.map((doc, i) => (
                                  <div key={i}>
                                    <Card
                                      onClick={() =>
                                        handleToggleDoc(
                                          true,
                                          `${process.env.REACT_APP_MAIN_URL}${doc.file}`,
                                          doc.name
                                        )
                                      }
                                      style={{
                                        background: "#f1f1f1",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Card.Body
                                        style={{
                                          padding: "10px 15px",
                                          color: "#7e8b9a",
                                        }}
                                      >
                                        {doc.name}
                                      </Card.Body>
                                    </Card>
                                  </div>
                                ))}
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Form.Group>
                              <Form.Label>Description *</Form.Label>
                              <Form.Control
                                as="textarea"
                                value={values.description}
                                onChange={handleChange}
                                name="description"
                                isInvalid={!_.isEmpty(errors.description)}
                              />
                              {errors.description && (
                                <Form.Control.Feedback type="invalid">
                                  {errors.description as string}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          variant="primary"
                          type="submit"
                          className="next action-button float-end"
                        >
                          Submit
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CreateViolation;
