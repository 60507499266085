import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );
  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = React.memo(() => {
  const [menuItems, setMenuItems] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const fetchMenuData = async () => {
      const defaultRole = localStorage.getItem("defaultRole");
      if (defaultRole) {
        setMenuItems(JSON.parse(defaultRole).menus);
      } else {
        console.error("No role data found.");
      }
    };

    fetchMenuData();
  }, []);

  return (
    <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
      <li className="nav-item static-item">
        <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
          <span className="default-icon">Main</span>
          <span
            className="mini-icon"
            data-bs-toggle="tooltip"
            title="Social"
            data-bs-placement="right"
          >
            -
          </span>
        </Link>
      </li>
      <li className={`${location.pathname === "/" ? "active" : ""} nav-item `}>
        <Link
          className={`${location.pathname === "/" ? "active" : ""} nav-link `}
          aria-current="page"
          to="/"
        >
          <OverlayTrigger placement="right" overlay={<Tooltip>Home</Tooltip>}>
            <i className="icon material-symbols-outlined">home</i>
          </OverlayTrigger>
          <span className="item-name">Home</span>
        </Link>
      </li>
      {menuItems.map((menu) => (
        <li
          key={menu.id}
          className={`${
            location.pathname ===
            `/dashboard/app/${menu.name.toLowerCase().replace(/\s+/g, "-")}`
              ? "active"
              : ""
          } nav-item`}
        >
          <Link
            className={`${
              location.pathname ===
              `/dashboard/app/${menu.name.toLowerCase().replace(/\s+/g, "-")}`
                ? "active"
                : ""
            } nav-link`}
            aria-current="page"
            to={`/dashboard/app/${menu.name
              .toLowerCase()
              .replace(/\s+/g, "-")}`}
          >
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip>{menu.name}</Tooltip>}
            >
              <i className="icon material-symbols-outlined">{menu.icon}</i>
            </OverlayTrigger>
            <span className="item-name">{menu.name}</span>
          </Link>
        </li>
      ))}
    </Accordion>
  );
});

export default VerticalNav;
